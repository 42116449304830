<template>
  <div class="pool_list">
    <tw-header :title="$t('BreadCrumbs.Export Customs Permit List')">
      <template v-slot:column>
        <tw-button v-if="canUpload" type="secondary" size="small" icon="plus" style="margin-left: 24px" @click="showUpload">
          Upload
        </tw-button>
      </template>
    </tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <TwPermitUpload :isShowUpload="isShowUpload" @close="closeUpload" :processType="processType" />

      <el-form v-if="!forbidden" class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Declaration No')" prop="declarationNo">
            <el-input type="text" v-model="searchForm.declarationNo" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Permit Date')" prop="dateOfPermit">
            <el-date-picker
              v-model="searchForm.dateOfPermit"
              type="daterange"
              range-separator="to"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
              @change.native="onDateRangeChange"
              start-placeholder="Start date"
              end-placeholder="End date"
              :disabled="searchForm.permitDateData">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.Declare Date')" prop="declarationDate">
            <el-date-picker
              v-model="searchForm.declarationDate"
              type="daterange"
              range-separator="to"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
              @change.native="onDateRangeChange"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
            <el-input type="text" v-model="searchForm.invoiceNo" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Shipper')" prop="shipperName">
            <el-input type="text" v-model="searchForm.shipperName" :disabled="searchForm.shipperSearchData" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Consignee')" prop="consigneeName">
            <el-input type="text" v-model="searchForm.consigneeName" :disabled="searchForm.consigneeSearchData" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Main Goods')" prop="goodsName">
                <el-input type="text" v-model="searchForm.goodsName" :disabled="searchForm.mainGoodsSearchData" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Quantity')" prop="totalQuantity">
                <el-input-number :controls="false" v-model="searchForm.totalQuantity" placeholder="Enter keyword" :min="-maxNum" :max="maxNum" />
              </el-form-item>
              <el-form-item :label="$t('Label.Invoice Amount')" prop="totalAmount">
                <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange amount_range" style="width: 235px;padding-left:4px">
                  <el-input-number :controls="false" placeholder="Min" name="" class="el-range-input" v-model="searchForm.invoiceAmountFrom" :disabled="searchForm.amountSearchData" :min="-maxNum" :max="maxNum" />
                  <span class="el-range-separator">to</span>
                  <el-input-number :controls="false" placeholder="Max" name="" class="el-range-input" v-model="searchForm.invoiceAmountTo" :disabled="searchForm.amountSearchData" :min="-maxNum" :max="maxNum" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('Label.Vessel Name')" prop="vesselName">
                <el-input type="text" v-model="searchForm.vesselName" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Upload Date')" prop="receiveDate">
                <el-date-picker
                  v-model="searchForm.receiveDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Ref No(Shipper)')" prop="shippersReferenceNo">
                <el-input type="text" v-model="searchForm.shippersReferenceNo" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Section Code(Shipper)')" prop="shippersSectionCode">
                <el-input type="text" v-model="searchForm.shippersSectionCode" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Remarks(Shipper)')" prop="remarksShipper">
                <el-input type="text" v-model="searchForm.remarksShipper" placeholder="Enter keyword" maxlength="70"></el-input>
              </el-form-item>
              <el-form-item v-show="!selection" :label="$t('Label.Linking Status')" prop="linkingFlag">
                <el-select v-model="linkingFlag" placeholder="Select" collapse-tags clearable>
                  <el-option
                    v-for="status in PERMIT_LINK_FLG_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Label.Amendment')" prop="amendmentFlg">
                <el-select v-model="searchForm.amendmentFlg" placeholder="Select" collapse-tags clearable>
                  <el-option
                    v-for="status in AMENDMENT_FLG_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="!selection" label=" " prop="includeDeletedData">
                <el-checkbox border class="border_none" v-model="includeDeletedDataCheck">{{$t('Label.Include Deleted Data')}}</el-checkbox>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label=" " prop="mainGoodsSearchData">
                <el-checkbox border class="border_none" v-model="searchForm.mainGoodsSearchData" @change="onSearchDataChange(searchForm.mainGoodsSearchData, 'goodsName')">{{$t('Label.Main Goods')}} - Blank</el-checkbox>
              </el-form-item>
              <el-form-item label=" " prop="amountSearchData">
                <el-checkbox border class="border_none" v-model="searchForm.amountSearchData" @change="onSearchDataChange(searchForm.amountSearchData, 'invoiceAmount')">{{$t('Label.Invoice Amount')}} - Blank</el-checkbox>
              </el-form-item>
              <el-form-item label=" " prop="shipperSearchData">
                <el-checkbox border class="border_none" v-model="searchForm.shipperSearchData" @change="onSearchDataChange(searchForm.shipperSearchData, 'shipperName')">{{$t('Label.Shipper')}} - Blank</el-checkbox>
              </el-form-item>
              <el-form-item label=" " prop="consigneeSearchData">
                <el-checkbox border class="border_none" v-model="searchForm.consigneeSearchData" @change="onSearchDataChange(searchForm.consigneeSearchData, 'consigneeName')">{{$t('Label.Consignee')}} - Blank</el-checkbox>
              </el-form-item>
              <el-form-item label=" " prop="permitDateData">
                <el-checkbox border class="border_none" v-model="searchForm.permitDateData" @change="onSearchDataChange(searchForm.permitDateData, 'dateOfPermit')">{{$t('Label.Permit Date')}} - Blank</el-checkbox>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>
          <div class="delete_area">
            <tw-button
              v-if="selection && items"
              type="default"
              size="medium"
              @click="cancel"
            >
              Cancel
            </tw-button>
            <tw-button
              v-if="selection && items"
              type="primary"
              size="medium"
              :disabled="selectionItems.length <= 0"
              @click="deleteConfirm"
              class="delete_button"
            >
              Delete
          </tw-button>
          </div>
          <el-dropdown v-if="items && items.length > 0 && !selection" trigger="click" placement="bottom" @command="onDropDown" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
              <el-dropdown-item v-if="editableFlag" command="deleteMode">{{$t('Label.Delete Mode')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system
        :schemas="schemas"
        :items="items"
        :pageSize="limit"
        :totalCount="totalCount"
        :offset="offset"
        :selection="selection"
        :serverPagination="true"
        :rowKey="getRowKey"
        @selection-change="selectionChange"
        @row-click="rowClick"
        @paging="onPaging"
        ref="table"
        mode="POOL"
      />

      <TwReceiveHistory
        ref="twReceiveHistory"
        v-show="forbidden === false"
        :permitInfo="permitInfo"
        @download="download"
      />
    </div>

    <tw-drawer :close-confirm="true" @close="closeConfirm" mode="edit">
      <template slot>
        <tw-permit-detail-inner v-if="drawerShow" :processType="processType" :declarationNo="declarationNo" :declarationDate="declarationDate" :trx="trx" :tradingId="tradingId" :editableFlag="editableFlag" @deleted="deleted" />
      </template>
    </tw-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import { PROCESS_TYPE, DEFAULT_PAGENATION, DELETE_FLG, PERMIT_LINK_FLG, PERMIT_LINK_FLG_VARIABLES, AMENDMENT_FLG, AMENDMENT_FLG_VARIABLES } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { collapseTransition } from '@/utils/nextFrame';
import { numberToString, clearSearchForm } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/ListExportCustomsPermitListSchema.json';
import TwReceiveHistory from '@/components/organisms/TwReceiveHistory';
import TwPermitUpload from '@/components/organisms/TwPermitUpload';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwPermitDetailInner from '@/components/templates/TwPermitDetailInner';
import { tsvDownLoad } from '@/utils/listToTsvUtils.js';
import dayjs from 'dayjs';

export default {
  name: 'ListExportCustomsPermitList',
  mixins: [mixinEntityList, collapseTransition],
  components: {
    TwReceiveHistory,
    TwPermitUpload,
    TwPermitDetailInner,
    TwDrawer,
  },
  data() {
    return {
      processType: PROCESS_TYPE.ECPERMIT,
      schemas: schemas,
      items: null,
      selection: false, // 一括削除時に切り替え
      searchForm: {
        declarationNo: '',
        dateOfPermit: '',
        declarationDate: '',
        invoiceNo: '',
        shipperName: '',
        consigneeName: '',
        goodsName: '',
        totalQuantity: undefined,
        invoiceAmountFrom: undefined,
        invoiceAmountTo: undefined,
        vesselName: '',
        receiveDate: '',
        shippersReferenceNo: '',
        shippersSectionCode: '',
        remarksShipper: '',
        mainGoodsSearchData: null,
        amountSearchData: null,
        shipperSearchData: null,
        consigneeSearchData: null,
        permitDateData: null,
        includeDeletedData: false,
        amendmentFlg: null,
      },
      includeDeletedDataCheck: false,
      linkingFlag: null,
      PERMIT_LINK_FLG_VARIABLES: PERMIT_LINK_FLG_VARIABLES,
      DELETE_FLG: DELETE_FLG,
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      isShowUpload: false,
      drawerShow: false,
      declarationNo: '',
      declarationDate: '',
      trx: '',
      tradingId: '',
      lastQuery: {},
      selectionItems: [],
      deleteLimit: 30, // 削除BFFを並列処理時の一回の最大接続数
      editableFlag: false, // アップロード、削除可能フラグ
      maxNum: 10 ** 16, // 300桁
      forbidden: null, // 認可エラーフラグ
      permitInfo: 'E', // 許可書区分（E=輸出、D=輸入）
      AMENDMENT_FLG_VARIABLES
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Export Customs Permit List')},
      ];
    },
    isPic() {
      // ユーザーロールが担当者か
      return this.$store.getters.isPic;
    },
    canUpload() {
      // アップロード可能ユーザ
      return this.editableFlag && this.isPic;
    },
  },
  created() {
    if (this.$store.state.drawerShow) this.$store.commit('SET_DRAWER', false);
    const queries = this.$store.getters.getQueries('EX_CUSTOM_PERMIT');
    if (queries) {
      this.searchForm = queries;
      this.includeDeletedDataCheck = this.searchForm.includeDeletedData;
    } else {
      // 検索初期条件：Receive Data直近1ヶ月昇順
      const today = dayjs().hour(0).minute(0).second(0);
      this.searchForm.receiveDate = [
        today.subtract(1, 'month').format(),
        today.format(),
      ]
    }
    this.fetch();
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      return {
        declarationNo: f.declarationNo,
        dateOfPermitFrom: f.dateOfPermit && f.dateOfPermit.length ? dayjs(this.getStartDate(f.dateOfPermit)).format('YYYYMMDD') : null,
        dateOfPermitTo: f.dateOfPermit && f.dateOfPermit.length ? dayjs(this.getEndDate(f.dateOfPermit)).format('YYYYMMDD') : null,
        declarationDateFrom: f.declarationDate && f.declarationDate.length ? dayjs(this.getStartDate(f.declarationDate)).format('YYYYMMDD') : null,
        declarationDateTo: f.declarationDate && f.declarationDate.length ? dayjs(this.getEndDate(f.declarationDate)).format('YYYYMMDD') : null,
        invoiceNo: f.invoiceNo,
        shipperName: f.shipperName,
        consigneeName: f.consigneeName,
        goodsName: f.goodsName,
        cargoQuantity: numberToString(f.totalQuantity),
        invoiceAmountFrom: numberToString(f.invoiceAmountFrom),
        invoiceAmountTo: numberToString(f.invoiceAmountTo),
        vesselName: f.vesselName,
        createDateFrom: this.getStartDate(f.receiveDate),
        createDateTo: this.getEndDate(f.receiveDate),
        shippersReferenceNo: f.shippersReferenceNo,
        shippersSectionCode: f.shippersSectionCode,
        remarksShipper: f.remarksShipper,
        mainGoodsSearchData: f.mainGoodsSearchData ? f.mainGoodsSearchData : null,
        amountSearchData: f.amountSearchData ? f.amountSearchData : null,
        shipperSearchData: f.shipperSearchData ? f.shipperSearchData : null,
        consigneeSearchData: f.consigneeSearchData ? f.consigneeSearchData : null,
        permitDateData: f.permitDateData ? f.permitDateData : null,
        includeDeletedData: f.includeDeletedData,
        amendmentFlg: f.amendmentFlg || f.amendmentFlg === AMENDMENT_FLG.OFF ? f.amendmentFlg : undefined,
        limit: this.limit,
        offset: this.offset,
        sortType: this.sort ? this.sort.sortType : null,
        target: this.sort ? this.sort.target : null,
      }
    },
    async fetch(queries) {
      // bff_ncp_2 輸出許可書プール一覧照会BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/nc-pool-datas/export/search',
          path: {
            companyId: this.$store.state.companyId,
          },
          query: {...queries || this.transformQueries(), linkingFlag: this.linkingFlag }
        }
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      await $api.request(params)
      .then(res => {
        this.items = res.naccspools && res.naccspools.length ? this.setIndex(res.naccspools) : [];
        this.editableFlag = res.editableFlag || false;
        this.totalCount = res.totalCount;
        this.forbidden = false;
        return Promise.resolve();
      })
      .catch(err => {
        this.items = [];
        this.sort = null;
        if (err.statusCode === 403) {
          this.forbidden = true;
        }
        this.$store.dispatch('SHOW_ALERT', err.message);
        return Promise.reject();
      });
    },
    search() {
      this.offset = 0;
      this.searchForm.includeDeletedData = this.includeDeletedDataCheck;
      this.selectionItems = [];
      this.items = null;
      this.$store.commit('SET_QUERIES', {key: 'EX_CUSTOM_PERMIT', queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.includeDeletedDataCheck = false;
      if (!this.selection) this.linkingFlag = null;
      this.$store.commit('SET_QUERIES', {key: 'EX_CUSTOM_PERMIT', queries: _.cloneDeep(this.searchForm)});
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        this.sort = {
          target: target,
          sortType: sortType,
        };
        // NOTE: 一覧の区分値がないためソートターゲットを変換
        if (this.sort.target === 'updater') this.sort.target = 'updaterUserName';
        else if (this.sort.target === 'tradingFlowId') this.sort.target = 'tradingId';
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      this.currentPage = currentPage;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sortType: this.sort ? this.sort.sortType : null,
        target: this.sort ? this.sort.target : null,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_ncp_2 輸出許可書プール一覧照会BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-pooldata-bff-api',
            apiCode: 'get_/v1/nc-pool-datas/export/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined, linkingFlag: null }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.naccspools);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    },
    // アップロードエリアを表示します
    showUpload() {
      this.isShowUpload = true;
    },
    closeUpload() {
      this.isShowUpload = false;
    },
    rowClick(row) {
      // console.log(row)
      this.declarationNo = row.declarationNo;
      this.declarationDate = row.declarationDate;
      this.trx = row.trx;
      this.tradingId = row.tradingId || '';
      this.drawerShow = true;
      this.$store.commit('SET_DRAWER', true);
    },
    // ドロワーを閉じる
    closeConfirm() {
      this.$store.commit('SET_DRAWER', false);
      this.drawerShow = false;
    },
    // ドロワー内で許可書削除後
    deleted() {
      this.fetch()
      .finally(() => {
        this.closeConfirm();
        this.$store.commit('END_PROCESS');
      })
    },
    // テーブルのrow-keyを返却します（チェック状態保持）
    getRowKey(row) {
      // NOTE: declarationNoは複数存在する場合があるのでcurrentPageとindexで一意にして返却
      return `${row.currentPage}_${row.index}_${row.declarationNo}`;
    },
    // テーブル表示時にrow-keyを一意にするためにrowにcurrentPageとindexを付与します
    setIndex(array) {
      for (let i = 0; i < array.length; i++) {
        array[i] = { ...array[i], currentPage: this.currentPage, index: i };
      }
      return array;
    },
    // 許可書一括削除コンファーム
    deleteConfirm() {
      this.$store
      .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete the files?')
      .then(() => {
        this.selectionDelete();
      })
      .catch(() => {});
    },
    // 許可書を一括削除します
    async selectionDelete() {
      this.$store.commit('START_PROCESS');

      // 結果を格納
      let results = { result: true, successCount: 0, errCount: 0 }

      // 配列のlength分、削除BFFを呼び出し
      // 並列で処理 一度にリクエストする最大接続数 deleteLimit = 30
      const requestItems = _.chunk(this.selectionItems, this.deleteLimit);
      for (let i = 0; i < requestItems.length; i++) {
        // eslint-disable-next-line no-unused-vars
        await Promise.allSettled(requestItems[i].map(async (item, index) => {
          // console.log(item, index)
          await this.deletePermit(item.declarationNo, item.declarationDate)
          .then(() => { return Promise.resolve() })
          .catch((err) => { return Promise.reject(err.message) });
        })).then((promiseResults) => promiseResults.forEach((promiseResult) => {
          // console.log(promiseResult)
          if (promiseResult.status === 'rejected') {
            results.result = false;
            results.errCount += 1;
          } else results.successCount += 1;
        }));
      }
      this.$refs.table.clearSelection(); // チェックボックスをクリア
      this.searchForm.includeDeletedData = this.includeDeletedDataCheck;
      this.$store.commit('END_PROCESS');
      // resultに応じてアラートかコンプリートを表示
      if (!results.result) this.$store.dispatch('SHOW_ALERT', `Data deleted successfully. ${results.successCount}<br />Failed to delete the data. ${results.errCount}`);
      else this.$store.dispatch('SHOW_COMPLETED');
      this.cancel();
    },
    // 許可書プール削除BFF
    async deletePermit(declarationNo, declarationDate) {
      // bff_ncp_9 輸出許可書プール削除BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/nc-pool-datas/export/delete',
          query: {
            declarationNo: declarationNo,
            declarationDate: declarationDate,
          }
        }
      };
      await $api.request(params)
      .then(res => { return Promise.resolve(res) })
      .catch(err => { return Promise.reject(err) });
    },
    // セレクト切り替え
    selectionChange(multipleSelection) {
      this.selectionItems = multipleSelection;
    },
    // ドロップダウン
    async onDropDown(command) {
      if (command === 'tsv') {
        const items = await this.fetchAll();
        tsvDownLoad(this.schemas, items);
      } else if (command === 'deleteMode') {
        if (!this.selection) {
          this.searchForm.includeDeletedData = false;
          this.linkingFlag = String(PERMIT_LINK_FLG.OFF);
        } else {
          this.linkingFlag = null;
        }
        this.selection = !this.selection;
        await this.$refs.table.clearSelection();
        this.items = null;
        await this.fetch();
      }
    },
    // 一括削除モード解除
    cancel() {
      this.items = null;
      this.linkingFlag = null;
      this.fetch(); // 一覧を再取得
      this.selection = false;
    },
    // 空白検索フラグチェック変更時
    onSearchDataChange(val, target) {
      // チェックON時、対象の検索フォーム内容を消去
      if (val) {
        if (target === 'invoiceAmount') {
          this.searchForm[`${target}From`] = undefined;
          this.searchForm[`${target}To`] = undefined;
        } else {
          this.searchForm[target] = '';
        }
      }
    },
    download(date) {
      $api.download(
        '', this.fileNameFormatter, 'get_/v1/nc-pool-datas/export/pool-list/tsv-download', 'tw-pooldata-bff-api', true,
        {
          receiveDateFrom: date[0],
          receiveDateTo: date[1]
        }
      )
        .then(() => {
          if(this.$refs?.twReceiveHistory) {
            this.$refs.twReceiveHistory.$refs?.downloadRangeDialog?.reset();
            this.$refs.twReceiveHistory.visibleHistoryDownloadDialog = false;
          }
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    fileNameFormatter(headerInfo) {
      return headerInfo['content-disposition']?.split('filename=')?.[1]?.replaceAll('"', '');
    }
  }
};
</script>

<style lang="scss" scoped>
.delete_area {
  margin: 0 16px 0 auto;
}
::v-deep .side_drawer {
  width: auto;
  min-width: 682px;

  &.edit {
    width: 1082px;
  }
}
</style>
